<template>
  <v-container fluid class="pt-0">
    <div class="text-h5">工作流配置</div>
    <div class="mt-1 mb-5" style="border-bottom: 1px solid #ddd;"></div>

    <v-tabs class="elevation-1">
      <v-tabs-slider></v-tabs-slider>
      <v-tab :href="`#tab-1`">基本信息</v-tab>
      <v-tab :href="`#tab-2`">工作流节点</v-tab>
      <v-tab :href="`#tab-3`">工作流步骤</v-tab>

      <!-- 1. 工作流基本信息卡片。 -->
      <v-tab-item key="1" style="margin-left:10px;" value="tab-1">
        <v-card flat min-height="400" tile>
          <v-form ref="form">
            <v-row class="mt-5" no-gutters>
              <v-col cols="12" md="6">
                <div class="mr-5">
                  <v-text-field
                    v-model="form.name"
                    class="text-body-2"
                    label="工作流名称"
                  />
                </div>
              </v-col>
              <v-col cols="12" md="6">
                <div class="mr-5">
                  <v-select
                    v-model="form.type"
                    :items="workflow_types"
                    :rules="[(v) => !!v || '工作流类型不能为空']"
                    class="text-body-2"
                    label="工作流类型"
                    placeholder="工作流类型"
                  >
                  </v-select>
                </div>
              </v-col>
              <v-col cols="12">
                <div class="mr-5">
                  <v-text-field
                    v-model="form.ticket_sn_prefix"
                    class="text-body-2"
                    label="工作单号前缀（生成工作单时，自动添加至工作单号前面）"
                  />
                </div>
              </v-col>
              <v-col cols="12">
                <div class="mr-5">
                  <div>限制表达式</div>
                  <v-textarea
                    v-model="form.limit_expression"
                    auto-grow
                    class="text-body-2"
                    dense
                    placeholder="限制周期({'period':24} 24小时), 限制次数({'count':1}在限制周期内只允许提交1次), 限制级别({'level':1} 针对(1单个用户 2全局)限制周期限制次数,默认 1特定用户);允许特定人员提交({'allow_persons':'zhangsan,lisi'}只允许张三提交工单,{'allow_depts':'1,2'}只允许部门id为1和2的用户提交工单，{'allow_roles':'1,2'}只允许角色id为1和2的用户提交工单)"
                    solo
                  >
                  </v-textarea>
                </div>
              </v-col>
              <v-col cols="12">
                <div class="mr-5">
                  <v-switch v-model="form.view_permission_check">
                    <template v-slot:label>
                      <div class="text-body-2">
                        工作单查看权限校验（默认开启。仅允许管理员，及工作单关联人查看工作单）
                      </div>
                    </template>
                  </v-switch>
                </div>
              </v-col>
            </v-row>
            <v-btn class="mt-1 mb-3 primary" small @click="addState()"
              >保存</v-btn
            >
          </v-form>
        </v-card>
      </v-tab-item>

      <!-- 2. 工作流节点 (State) 卡片。 -->
      <v-tab-item key="2" class="mx-3" value="tab-2">
        <v-card v-if="visible_states.length > 0" flat min-height="400" tile>
          <v-stepper alt-labels>
            <v-stepper-header>
              <template v-for="(item, index) in visible_states">
                <v-stepper-step :key="index" :step="index + 1">{{
                  item.name
                }}</v-stepper-step>
                <v-divider
                  v-if="item.state_type && item.state_type.value === 0"
                  :key="index + 99"
                ></v-divider>
              </template>
            </v-stepper-header>
          </v-stepper>
          <v-simple-table>
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-left">序号</th>
                  <th class="text-left">节点名称</th>
                  <th class="text-left">节点类型</th>
                  <th class="text-left">是否隐藏</th>
                  <th>操作</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(item, index) in workflow_states"
                  :key="item.id"
                  :style="index % 2 === 0 ? 'background: rgb(242,242,242)' : ''"
                >
                  <td>{{ item.order_id }}</td>
                  <td>{{ item.name }}</td>
                  <td>{{ item.state_type.text }}</td>
                  <td>
                    <span :class="item.is_hidden ? 'warning--text' : ''">{{
                      item.is_hidden ? "是" : "否"
                    }}</span>
                  </td>
                  <td>
                    <v-btn
                      v-if="item.state_type && item.state_type.value === 0"
                      class="primary"
                      small
                      @click="editState(item)"
                      >编辑
                    </v-btn>
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
          <v-btn class="mt-1 mb-3 primary" small @click="addState()"
            >添加</v-btn
          >
        </v-card>
        <div v-else class="text-subtitle-1 mt-3 pb-3">暂无数据。</div>
      </v-tab-item>

      <!-- 3. 工作流步骤 (Transition) 卡片 -->
      <v-tab-item key="3" style="margin-left:10px;" value="tab-3">
        <v-card flat min-height="400" tile>
          <h4>工作流步骤</h4>
        </v-card>
      </v-tab-item>
    </v-tabs>

    <v-dialog v-model="state_modal" max-width="800px" scrollable>
      <add-edit-state
        :data="state_data"
        @closeStateModal="closeStateModal()"
      ></add-edit-state>
    </v-dialog>
  </v-container>
</template>

<script>
import {
  getStateListByWorkflow,
  getWorkflowDetail,
  getWorkflowTypeList,
} from "@/api/workflows";

import addEditState from "./components/add-or-edit-state";

export default {
  name: "addoredit_workflow",

  props: {
    id: [String, Number],
  },
  data: () => ({
    form: {
      name: "",
      type: "",
      limit_expression: "",
      view_permission_check: true,
    },
    workflow_types: [],
    workflow_states: [],
    userList: [],
    group_users: [],
    models: {},
    group_permissions: [],
    state_modal: false,
    state_data: "",
  }),
  computed: {
    visible_states() {
      return this.workflow_states.filter((item) => item.is_hidden === false);
    },
  },
  async mounted() {
    await this.doGet();
  },
  methods: {
    async doGet() {
      // chenxi 20201011
      // 获取工作流详情
      let result = await getWorkflowDetail(this.id);
      console.log(result.limit_expression.length);
      this.form = result;

      // 获取工作流类型列表
      result = await getWorkflowTypeList();
      this.workflow_types = result.results.map((item) => ({
        value: item.id,
        text: item.name,
      }));

      // 获取当前 workflow 的所有 State.
      result = await getStateListByWorkflow(this.id);
      this.workflow_states = result.results;
    },

    addState() {
      this.state_data = {};
      this.state_modal = true;
    },

    editState(item) {
      this.state_data = item;
      this.state_modal = true;
    },

    closeStateModal() {
      this.state_data = {};
      this.state_modal = false;
    },
  },

  components: {
    "add-edit-state": addEditState,
  },
};
</script>
<style lang="stylus"></style>
