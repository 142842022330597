<template>
  <v-container fluid>
    <v-alert
      v-model="alert"
      :type="alert_type"
      dense
      dismissible
      max-height="50"
      style="position:fixed;z-index:9;width:70%;margin-left:150px;margin-top:200px;padding-top:10px;padding-bottom:40px;"
    >
      {{ alert_content }}
    </v-alert>
    <v-card flat max-height="800px" min-height="500px" tile>
      <v-card-title>
        {{ data && data.name ? "编辑节点" : "添加节点" }}</v-card-title
      >
      <v-divider></v-divider>
      <v-card-text class="pt-2">
        <v-form ref="form">
          <v-row no-gutters>
            <v-col cols="12" md="6">
              <div class="pr-5">
                <div>节点名称</div>
                <v-text-field
                  v-model="form.name"
                  :rules="[(v) => !!v || '节点名称不能为空']"
                  dense
                  placeholder="节点名称"
                  solo
                >
                </v-text-field>
              </div>
            </v-col>
            <v-col cols="12" md="6">
              <div class="pr-5">
                <div>节点类型</div>
                <!-- chenxi 20201011
                    节点类型值可以为 0, 但是 !!v 会将 0 判断为 false, 因此需要使用 (!!v || v==0), 意思是 v 不为空，或者 v==0 都是合法值。
                -->
                <v-select
                  v-model="form.state_type"
                  :items="state_types"
                  :rules="[(v) => !!v || v === 0 || '节点类型不能为空']"
                  dense
                  placeholder="节点类型"
                  solo
                >
                </v-select>
              </div>
            </v-col>
          </v-row>
          <v-row no-gutters>
            <v-col cols="12" md="6">
              <div class="pr-5">
                <div>序号</div>
                <v-text-field
                  v-model="form.order_id"
                  :rules="[(v) => !!v || '序号不能为空']"
                  dense
                  placeholder="序号"
                  solo
                >
                </v-text-field>
              </div>
            </v-col>
            <v-col cols="12" md="6">
              <div class="pr-5">
                <div>处理人类型</div>
                <v-select
                  v-model="form.participant_type"
                  :items="participant_types"
                  :rules="[(v) => !!v || v === 0 || '处理人类型不能为空']"
                  dense
                  placeholder="处理人类型"
                  solo
                >
                </v-select>
              </div>
            </v-col>
          </v-row>
          <v-row no-gutters>
            <v-col cols="12" md="6">
              <div class="pr-5">
                <div>是否隐藏</div>
                <v-switch v-model="form.is_hidden"></v-switch>
              </div>
            </v-col>
            <v-col cols="12" md="6">
              <div class="pr-5">
                <div>是否允许撤回</div>
                <v-switch v-model="form.enable_retreat"></v-switch>
              </div>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions class="px-3 py-3">
        <v-spacer></v-spacer>
        <v-btn class="warning darken-1" text @click="closeModal()">取消</v-btn>
        <v-btn class="primary darken-1" text @click="saveState()">确定</v-btn>
      </v-card-actions>
    </v-card>
  </v-container>
</template>

<script>
import { updateState } from "@/api/workflows";

export default {
  name: "addEditState",
  props: {
    data: [Object],
  },
  data: () => ({
    form: {
      id: "",
      name: "",
      state_type: "",
      participant_type: 0,
      order_id: 0,
      is_hidden: false,
      enable_retreat: false,
    },
    state_types: [
      { value: 0, text: "普通状态" },
      { value: 1, text: "初始状态" },
      { value: 2, text: "结束状态" },
    ],
    participant_types: [
      { value: 0, text: "无处理人" },
      { value: 1, text: "个人" },
      { value: 2, text: "部门" },
      { value: 3, text: "角色" },
    ],
    alert: false,
    alert_type: "warning",
    alert_content: "",
  }),
  methods: {
    saveState() {
      let _this = this;

      updateState(this.form)
        .then((result) => {
          console.log(result);
          _this.alert_type = "success";
          _this.alert_content = "更新 State 成功。";
          _this.alert = true;
          setTimeout(() => {
            _this.$emit("closeStateModal");
            _this.alert = false;
          }, 1500);
        })
        .catch((error) => {
          _this.alert_type = "warning";
          _this.alert_content = error;
          _this.alert = true;
          setTimeout(() => {
            _this.$emit("closeStateModal");
            _this.alert = false;
          }, 4000);
        });
    },

    closeModal() {
      this.form = {
        name: "",
        state_type: "",
        participant_type: 0,
        order_id: 0,
        is_hidden: false,
        enable_retreat: false,
      };
      this.$emit("closeStateModal");
    },
  },
  watch: {
    // chenxi 20201011
    // 在状态列表页面点击不同状态行的"编辑"按钮，反复调用此组件时，页面中展示的 form 是缓存值，不是最新值。
    // 这里需要监听 data 的变化，更新 form.
    data(newValue, oldValue) {
      if (newValue && newValue.id) {
        this.form = {
          id: newValue.id,
          name: newValue.name,
          state_type: newValue.state_type.value,
          participant_type: newValue.participant_type,
          order_id: newValue.order_id,
          is_hidden: newValue.is_hidden,
          enable_retreat: newValue.enable_retreat,
        };
      }
    },
  },
  mounted() {
    this.form = {
      id: this.data.id,
      name: this.data.name,
      state_type: this.data.state_type.value,
      participant_type: this.data.participant_type,
      order_id: this.data.order_id,
      is_hidden: this.is_hidden,
      enable_retreat: this.data.enable_retreat,
    };
  },
};
</script>
