import request from "@/request";

export const getWorkflowList = (params) =>
  request.get("/api/workflows/workflow", { params });
export const getWorkflowDetail = (id) =>
  request.get(`/api/workflows/workflow/${id}/`);
export const addWorkflow = (data) =>
  request.post(`/api/workflows/workflow/`, data);
export const updateWorkflow = (data) =>
  request.patch(`/api/workflows/workflow/${data.id}/`, data);

export const getStateListByWorkflow = (id) =>
  request.get(`/api/workflows/state/?workflow=${id}`);
export const addState = (data) => request.post(`/api/workflows/state/`, data);
export const updateState = (data) =>
  request.patch(`/api/workflows/state/${data.id}/`, data);

export const getWorkflowTypeList = (params) =>
  request.get("/api/workflows/workflowtype", { params });
